<template>
  <base-section
    id="pro-features2"
  >
    <base-section-heading title="Возможности программы" />
    <v-container style="font-size: large">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
        >
          Программа работает с многопользовательской базой данных и позволяет выполнять функции: ведение базы заказчиков (клиентов),
          ведение базы объектов, регистрация заявки клиента на подбор исполнителей; открытие вакансий, размещение вакансий на сайтах с работой,
          поиск и регистрация соискателей, подбор и назначение кандидатов на вакансии; продвижение кандидатов по воронке подбора; закрытие вакансии;
          фиксация назначения исполнителя на заявку, факта исполнения заявки; фиксация суммы вознаграждения заказчиком исполнителю за оказанный объем услуг;
          управление договорами с заказчиками и исполнителями услуг; контроль и осуществление финансового взаимодействия;
          формирование отчетности для заказчика и исполнителя услуг.
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          Ведите базу данных исполнителей со всей необходимой информацией в быстром доступе:
          документы, история взаимодействия, история контактов, файлы, договоры и т.д.
        </v-col>

        <v-col
          cols="12"
          md="7"
        >
          <v-card
            elevation="16"
            class="mb-12"
          >
            <base-img
              max-width="100%"
              src="/imgs/img2.png"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProFeat',

    data: () => ({
      features: [
        ['Components', 5, '40+'],
        ['Example Pages', 3, '10+'],
        ['Vue CLI Support', true, true],
        ['Bugfixes and Issues', false, true],
        ['6 Months Free Updates', false, true],
        ['Supports Vuetify', false, true],
        ['Price', 'Free', '$59'],
      ],
    }),
  }
</script>
